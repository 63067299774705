import { HEIGHT_SIZE } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ANALYTICS_FILTERS_HEIGHT,
  ANALYTICS_INNER_TABS_HEIGHT,
  ANALYTICS_PLAN_FACT_CONTENT_HEADER_HEIGHT,
} from 'src/pages/analytics/AnalyticsInner/consts';

export default (): Record<string, SxProps<Theme>> => ({
  contentContainer: {
    height: `calc(100vh - (${
      HEIGHT_SIZE.headerHeight +
      ANALYTICS_FILTERS_HEIGHT +
      ANALYTICS_INNER_TABS_HEIGHT +
      ANALYTICS_PLAN_FACT_CONTENT_HEADER_HEIGHT
    }px))`,
    margin: '0 24px',
    overflow: 'scroll',
  },
});
