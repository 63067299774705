import { createSelector } from 'reselect';
import { vehicleIdSelector } from 'src/app/selectors';
import { getAmountFormatter } from 'src/dictionary/selector';
import { getAnalyticsMyoceanArticlesListValue } from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/selector';
import { getAnalyticsFilters } from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';

export default createSelector(
  [vehicleIdSelector, getAnalyticsFilters, getAnalyticsMyoceanArticlesListValue, getAmountFormatter],
  (boatId, filters, items, amountFormatter) => ({
    boatId,
    filters,
    items,
    amountFormatter,
  })
);
