import Button from '@core-ui/button';
import { TableCell, TableRow } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AmountFormatter } from 'src/app/types/common';
import type { ClientCostArticlePlanFactSchema } from 'src/generated';
import { makeUrlToDocumentsFromPlanFactClientArticles } from 'src/pages/analytics/AnalyticsInner/utils';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { AnalyticsPlanFactClientArticlesTableMonthCell } from '..';
import useStyles from './styles';

interface IProps {
  costArticle: ClientCostArticlePlanFactSchema;
  boatId: Nullable<number>;
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  monthInterval: string[];
  amountFormatter: AmountFormatter;
}

const AnalyticsPlanFactClientArticlesTableRow = (props: IProps) => {
  const { costArticle, boatId, dateFrom, dateTo, monthInterval, amountFormatter } = props;

  const history = useHistory();
  const styles = useStyles();

  const handleOpenInLedger = () => {
    history.push(
      makeUrlToDocumentsFromPlanFactClientArticles(boatId, costArticle.cost_article_clients_code, dateFrom, dateTo)
    );
  };

  return (
    <TableRow sx={styles.row}>
      <TableCell sx={styles.nameCell} shadow="dynamicRight">
        <Grid item container justifyContent="space-between">
          <Typography>{costArticle.cost_article_clients_name}</Typography>

          <Tooltip title={<FormattedMessage id="tooltip.open_relevant_documents_in_ledger" />} placement="top" arrow>
            <Button
              onClick={handleOpenInLedger}
              variant="text"
              // TODO: compact, size="small" и minimalSpacing не работают и не позволяют убрать паддинги иконки, нужно фиксить это в корке
              sx={styles.openInLedgerButton}
              icon={
                <SvgIcon fontSize="small">
                  <ArrowSquareOut weight="bold" />
                </SvgIcon>
              }
            />
          </Tooltip>
        </Grid>
      </TableCell>

      {monthInterval.map((month, index) => (
        <AnalyticsPlanFactClientArticlesTableMonthCell
          key={month}
          boatId={boatId}
          costArticleCode={costArticle.cost_article_clients_code}
          // количество и порядок месяцев в monthInterval и в параметре budget_per_month совпадают
          costArticlePlanFactDiff={costArticle.budget_per_month ? costArticle.budget_per_month[index] : null}
          amountFormatter={amountFormatter}
        />
      ))}
    </TableRow>
  );
};

export default AnalyticsPlanFactClientArticlesTableRow;
