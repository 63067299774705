import { Nullable } from '@core-ui/types';
import { ICostArticleResponseSchema } from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/types';

export const flattenMyOceanCostArticles = (
  costArticles?: Nullable<ICostArticleResponseSchema[]>,
  isRootArticles: boolean = true
): ICostArticleResponseSchema[] => {
  if (!costArticles?.length) {
    return [];
  }

  const result: ICostArticleResponseSchema[] = [];

  costArticles.forEach((item) => {
    result.push({
      ...item,
      isRootArticle: isRootArticles ?? false,
    });

    if (item.children?.length) {
      result.push(...flattenMyOceanCostArticles(item.children, false));
    }
  });

  return result;
};
