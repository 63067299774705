import { HeaderCell, TableRow } from '@core-ui/table';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  MONTH_EXPENSES_TABLE_MONTH_HEADER_PREFIX,
  MONTH_EXPENSES_TABLE_NAME_HEADER_ID,
  MONTH_EXPENSES_TABLE_TOTAL_HEADER_ID,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/consts';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import selector from './selector';
import useStyles from './styles';

const AnalyticsExpensesClientArticlesMonthTableHeader = () => {
  const { monthHeaders, showRub } = useAppSelector(selector);

  const styles = useStyles();

  return (
    <TableHead>
      <TableRow>
        <HeaderCell sx={styles.nameCell} id={MONTH_EXPENSES_TABLE_NAME_HEADER_ID}>
          <FormattedMessage id="table.header.name" />
        </HeaderCell>

        <HeaderCell sx={styles.totalCell} shadow="right" id={MONTH_EXPENSES_TABLE_TOTAL_HEADER_ID}>
          <FormattedMessage id={showRub ? 'table.header.ca_total_rub' : 'table.header.ca_total_eur'} />
        </HeaderCell>

        {monthHeaders.map((header) => (
          <HeaderCell
            key={header}
            align="right"
            sx={styles.monthCell}
            id={MONTH_EXPENSES_TABLE_MONTH_HEADER_PREFIX + header}
          >
            <Box>{header}</Box>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AnalyticsExpensesClientArticlesMonthTableHeader;
