import { buttonBaseClasses, tableCellClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  nameCell: {
    [`&.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
      paddingLeft: 6,
    },
  },

  openInLedgerButton: {
    [`&.${buttonBaseClasses.root}`]: {
      padding: 0,
    },
  },
});
