import { useQuery } from '@core-ui/redux-router';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import { ANALYTICS_EXPENSES_VIEW, ANALYTICS_GRAPH_HEIGHT } from 'src/pages/analytics/AnalyticsInner/consts';
import { AnalyticsQueryParams } from 'src/pages/analytics/AnalyticsInner/types';
import {
  AnalyticsExpensesClientArticlesGeneralGraph,
  AnalyticsExpensesClientArticlesGeneralTable,
  AnalyticsExpensesClientArticlesMonthGraph,
  AnalyticsExpensesClientArticlesMonthTable,
} from './components';

const graphContainerStyles: SxProps<Theme> = { marginLeft: 0, marginY: 6 };

const AnalyticsExpensesClientArticles = () => {
  const query = useQuery<AnalyticsQueryParams>();
  const isGeneralViewSelected = query.view === ANALYTICS_EXPENSES_VIEW.GENERAL;

  return (
    <>
      <Grid item container wrap="nowrap" sx={graphContainerStyles} height={ANALYTICS_GRAPH_HEIGHT}>
        {isGeneralViewSelected ? (
          <AnalyticsExpensesClientArticlesGeneralGraph />
        ) : (
          <AnalyticsExpensesClientArticlesMonthGraph />
        )}
      </Grid>

      <Grid item width="100%" height="100%">
        {isGeneralViewSelected ? (
          <AnalyticsExpensesClientArticlesGeneralTable />
        ) : (
          <AnalyticsExpensesClientArticlesMonthTable />
        )}
      </Grid>
    </>
  );
};

export default AnalyticsExpensesClientArticles;
