import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import {
  resetAnalyticsFilters,
  setAnalyticsFiltersFromQuery,
  updateAnalyticsFiltersQuery,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/actions';
import AnalyticsPlanFactRoot from 'src/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot';
import {
  getAnalyticsPlanFact,
  resetAnalyticsPlanFact,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/actions';
import { ANALYTICS_INNER_TABS } from 'src/pages/analytics/AnalyticsInner/consts';
import { IAnalyticsInnerState } from 'src/pages/analytics/AnalyticsInner/reducer';
import { ANALYTICS_TABS } from 'src/pages/analytics/consts';

const key: keyof IAnalyticsInnerState = 'planFact';

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.ANALYTICS})/:analyticsTab(${ANALYTICS_TABS.ANALYTICS})/:analyticsInnerTab(${ANALYTICS_INNER_TABS.PLAN_FACT})`,
    component: AnalyticsPlanFactRoot,

    effects: [
      { events: ['on_enter'], trigger: resetAnalyticsPlanFact },
      { events: ['on_enter'], trigger: resetAnalyticsFilters },
      { events: ['on_enter'], trigger: setAnalyticsFiltersFromQuery },
      { events: ['on_enter'], trigger: updateAnalyticsFiltersQuery },
      { events: ['on_enter'], trigger: getAnalyticsPlanFact },
    ],
  },
];

export const PATHS = extractFlattenPaths(routes);

export default routes;
