import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import React from 'react';
import { AnalyticsPlanFactClientArticlesTableBody, AnalyticsPlanFactClientArticlesTableHeader } from './components';

const AnalyticsPlanFactClientArticlesTable = () => {
  return (
    <Grid item container>
      <Table stickyHeader>
        <AnalyticsPlanFactClientArticlesTableHeader />
        <AnalyticsPlanFactClientArticlesTableBody />
      </Table>
    </Grid>
  );
};

export default AnalyticsPlanFactClientArticlesTable;
