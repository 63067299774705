import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import {
  AnalyticsExpensesMyoceanArticlesMonthTableBody,
  AnalyticsExpensesMyoceanArticlesMonthTableHeader,
} from './components';

const AnalyticsExpensesMyoceanArticlesMonthTable = () => {
  return (
    <Grid item container>
      <Table stickyHeader>
        <AnalyticsExpensesMyoceanArticlesMonthTableHeader />
        <AnalyticsExpensesMyoceanArticlesMonthTableBody />
      </Table>
    </Grid>
  );
};

export default AnalyticsExpensesMyoceanArticlesMonthTable;
