import { combineReducers } from 'redux';
import expenses, { IAnalyticsExpensesState } from './AnalyticsExpenses/reducer';
import filters, { IAnalyticsFiltersState } from './AnalyticsFilters/reducer';
import planFact, { IAnalyticsPlanFact } from './AnalyticsPlanFact/reducer';

export interface IAnalyticsInnerState {
  filters: IAnalyticsFiltersState;
  expenses: IAnalyticsExpensesState;
  planFact: IAnalyticsPlanFact;
}

export default combineReducers({
  filters,
  expenses,
  planFact,
});
