import { shouldShowRub } from 'src/app/selectors';
import { getAmountFormatter } from 'src/dictionary/selector';
import { getAnalyticsPlanFactClientArticlesValue } from 'src/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getAnalyticsPlanFactClientArticlesValue, shouldShowRub, getAmountFormatter],
  (planFactValue, showRub, amountFormatter) => ({
    plannedTotal: planFactValue?.budget_total,
    factTotal: planFactValue?.fact_total,
    showRub,
    amountFormatter,
  })
);
