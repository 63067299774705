import { resetState, setLoading } from '@core-ui/reducers_handlers';
import { getData, setData } from '@core-ui/reducers_handlers/actions';
import { ClientArticlesPlanFactSchema } from 'src/generated';

export const reducerName = 'analytics.plan_fact.client_articles';

export const getClientArticlesPlanFact = getData(reducerName);
export const setClientArticlesPlanFact = setData<ClientArticlesPlanFactSchema>(reducerName);

export const setClientArticlesPlanFactLoading = setLoading(reducerName);

export const resetClientArticlesPlanFact = resetState(reducerName);
