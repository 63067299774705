import { MySkyLoader } from '@core-ui/icons';
import Container from '@mui/material/Container';
import { router } from 'src/configureStore';
import { ANALYTICS_ROUTE } from 'src/pages/analytics/routes';
import React, { CSSProperties } from 'react';
import useStyles from './styles';

const fallbackStyle: CSSProperties = {
  margin: 'auto',
};

const AnalyticsRoot = () => {
  const styles = useStyles();

  return (
    <Container maxWidth="xl" sx={styles.container}>
      {/* TODO: тут появятся табы с новым подразделом Reports */}

      {ANALYTICS_ROUTE.routes && router.render(ANALYTICS_ROUTE.routes, <MySkyLoader style={fallbackStyle} />)}
    </Container>
  );
};

export default AnalyticsRoot;
