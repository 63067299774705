export const Y_AXIS_WIDTH = 40;
export const BAR_GAP = 32;
export const BAR_HEIGHT = 242;
export const Y_AXIS_TICKS = 3;

export const RADIUS = 8;
export const X_RADIUS = RADIUS;
export const Y_RADIUS = RADIUS;

export const GRAPH_MARGINS = {
  top: 20,
  bottom: 40,
  left: Y_AXIS_WIDTH,
  right: 0,
};
