import {
  BAR_HEIGHT,
  RADIUS,
  X_RADIUS,
  Y_RADIUS,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/components/AnalyticsExpensesClientArticlesMonthGraph/consts';
import {
  BAR_GAP,
  Y_AXIS_WIDTH,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/components/AnalyticsExpensesMyoceanArticlesMonthGraph/consts';
import { Bar } from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/components/AnalyticsExpensesMyoceanArticlesMonthGraph/types';
import {
  TABLE_MIN_MONTH_CELL_WIDTH,
  TABLE_MIN_NAME_CELL_WIDTH,
  TABLE_MIN_TOTAL_CELL_WIDTH,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/components/AnalyticsExpensesMyoceanArticlesMonthTable/consts';
import {
  MONTH_EXPENSES_TABLE_MONTH_HEADER_PREFIX,
  MONTH_EXPENSES_TABLE_NAME_HEADER_ID,
  MONTH_EXPENSES_TABLE_TOTAL_HEADER_ID,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/consts';
import { IMonthTotal } from 'src/pages/analytics/AnalyticsInner/types';

const getFixedTableColumnsWidth = (): number => {
  let nameColumnHeaderWidth = TABLE_MIN_NAME_CELL_WIDTH;
  let totalColumnHeaderWidth = TABLE_MIN_TOTAL_CELL_WIDTH;

  const nameColumnHeader = document.getElementById(MONTH_EXPENSES_TABLE_NAME_HEADER_ID);
  const totalColumnHeader = document.getElementById(MONTH_EXPENSES_TABLE_TOTAL_HEADER_ID);

  if (nameColumnHeader) {
    nameColumnHeaderWidth = nameColumnHeader.getBoundingClientRect().width;
  }

  if (totalColumnHeader) {
    totalColumnHeaderWidth = totalColumnHeader.getBoundingClientRect().width;
  }

  return nameColumnHeaderWidth + totalColumnHeaderWidth;
};

const getMediumMonthCellWidth = (selectedMonths: string[]): number => {
  const monthIds = selectedMonths.map((month) => `${MONTH_EXPENSES_TABLE_MONTH_HEADER_PREFIX}${month}`);

  const monthHeadersTotalWidth = monthIds.reduce<number>((totalWidth, id) => {
    const header = document.getElementById(id);

    if (!header) {
      return totalWidth + TABLE_MIN_MONTH_CELL_WIDTH;
    }

    return totalWidth + header.getBoundingClientRect().width;
  }, 0);

  return monthHeadersTotalWidth / selectedMonths.length;
};

export const getBarWidths = (selectedMonths: string[]): number[] => {
  const fixedColumnsWidth = getFixedTableColumnsWidth();
  const mediumMonthColumnWidth = getMediumMonthCellWidth(selectedMonths);

  const fixedColumnsCompensationWidth = (fixedColumnsWidth - Y_AXIS_WIDTH) / selectedMonths.length - BAR_GAP;
  const cellWidth = fixedColumnsCompensationWidth + mediumMonthColumnWidth;

  return selectedMonths.map<number>(() => cellWidth);
};

export const getBarsByMonth = (selectedMonths: string[], items: IMonthTotal[]): Bar[] => {
  return selectedMonths.reduce<Bar[]>((acc, month) => {
    const monthTotal = items.reduce<number>((total, item) => {
      return item.monthName === month ? total + item.total : total;
    }, 0);

    acc.push({
      name: month,
      total: monthTotal,
    });

    return acc;
  }, []);
};

export const getExpensesBarPath = (
  barValue: number,
  barWidth: number,
  xScaleOffset: number,
  yScaleOffset: number
): string => {
  // если высота ноль, то столбец не рисуем вообще
  if (barValue === 0) {
    return '';
  }

  const yHeight = BAR_HEIGHT - yScaleOffset;

  // если значение не ноль, но рассчитанная из неё высота по оси Y меньше радиуса скругления столбца,
  // то рисуем только верхнюю часть столбца высотой 8 пикселей
  if (yHeight <= RADIUS) {
    return `
            M${xScaleOffset},${BAR_HEIGHT}
            a${X_RADIUS},${Y_RADIUS} 0 0 1 ${X_RADIUS},${-Y_RADIUS}
            h${barWidth - 2 * X_RADIUS}
            a${X_RADIUS},${Y_RADIUS} 0 0 1 ${X_RADIUS},${Y_RADIUS}
          `;
  }

  return `
          M${xScaleOffset},${yScaleOffset + Y_RADIUS}
          a${X_RADIUS},${Y_RADIUS} 0 0 1 ${X_RADIUS},${-Y_RADIUS}
          h${barWidth - 2 * X_RADIUS}
          a${X_RADIUS},${Y_RADIUS} 0 0 1 ${X_RADIUS},${Y_RADIUS}
          v${BAR_HEIGHT - yScaleOffset - Y_RADIUS}
          h${-barWidth}Z
      `;
};
