import { createSelector } from 'reselect';
import State from 'src/app/types/state';

export const getAnalyticsMyoceanArticlesList = (state: State) =>
  state.analytics.analyticsInner.expenses.myoceanArticles;
export const getAnalyticsMyoceanArticlesListValue = (state: State) =>
  state.analytics.analyticsInner.expenses.myoceanArticles.value;

export const getAnalyticsMyOceanArticlesTotal = createSelector([getAnalyticsMyoceanArticlesListValue], (items) => {
  return (
    items?.reduce((acc, costArticle) => {
      if (!costArticle.isRootArticle) {
        return acc;
      }

      return acc + (costArticle.total_price ?? 0);
    }, 0) ?? 0
  );
});
