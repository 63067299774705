import State from 'src/app/types/state';
import { formatTime, fromUnixTime } from 'src/app/utils/dates';
import { getCostCentersDictOptions } from 'src/dictionary/selector';
import { IAnalyticsFiltersState } from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/reducer';
import { createSelector } from 'reselect';

export const keys: Array<keyof IAnalyticsFiltersState> = ['dateFrom', 'dateTo', 'variability', 'costCenters'];

export const getAnalyticsFilters = (state: State) => state.analytics.analyticsInner.filters;

export const getAnalyticsFiltersSelectedMonths = createSelector([getAnalyticsFilters], (filters) => {
  const months = [];

  if (filters.dateFrom && filters.dateTo) {
    const dateFrom = fromUnixTime(Number(filters.dateFrom));
    const dateTo = fromUnixTime(Number(filters.dateTo));

    while (dateFrom <= dateTo) {
      months.push(formatTime(dateFrom, { dateFormat: 'MMMM-YY' }));
      dateFrom.setMonth(dateFrom.getMonth() + 1);
    }
  }

  return months;
});

export const getAnalyticsFiltersApplied = createSelector([getAnalyticsFilters], (filters) =>
  Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return Boolean(value);
  })
);

export default createSelector([getAnalyticsFilters, getCostCentersDictOptions], (filters, costCentersOptions) => ({
  filters,
  costCentersOptions,
}));
