import { createSelector } from 'reselect';
import { vehicleIdSelector } from 'src/app/selectors';
import { getAmountFormatter } from 'src/dictionary/selector';
import { getAnalyticsMyoceanArticlesListValue } from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/selector';
import {
  getAnalyticsFilters,
  getAnalyticsFiltersSelectedMonths,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';

export default createSelector(
  [
    getAnalyticsMyoceanArticlesListValue,
    vehicleIdSelector,
    getAnalyticsFilters,
    getAnalyticsFiltersSelectedMonths,
    getAmountFormatter,
  ],
  (items, boatId, filters, selectedMonths, amountFormatter) => ({
    items,
    boatId,
    filters,
    selectedMonths,
    amountFormatter,
  })
);
