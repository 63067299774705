import { ScrollMemory, useQuery } from '@core-ui/redux-router';
import { updateQuery } from '@core-ui/url';
import NoDataContainer from 'src/components/NoDataContainer';
import NoDataPlaceholder from 'src/components/NoDataPlaceholder';
import NoFiltratedDataPlaceholder from 'src/components/NoFiltratedDataPlaceholder';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getClientArticlesAnalyticsExpensesList } from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/actions';
import AnalyticsExpensesContent from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesContent';
import AnalyticsExpensesHeader from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesHeader';
import { resetAnalyticsFilters } from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/actions';
import { getAnalyticsFiltersApplied } from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { ANALYTICS_EXPENSES_VIEW, ANALYTICS_VIEW_QUERY_KEY } from 'src/pages/analytics/AnalyticsInner/consts';
import { AnalyticsQueryParams } from 'src/pages/analytics/AnalyticsInner/types';
import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { noDataSelector } from './selector';

export const ANALYTICS_EXPENSES_LIST_CONTAINER_ID = 'analytics-expenses-list-container';

const AnalyticsExpensesRoot = () => {
  const isFiltersApplied = useAppSelector(getAnalyticsFiltersApplied);

  const query = useQuery<AnalyticsQueryParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleResetFilters = () => {
    batch(() => {
      dispatch(resetAnalyticsFilters());
      dispatch(getClientArticlesAnalyticsExpensesList(null));
    });
  };

  const noDataContent = isFiltersApplied ? (
    <NoFiltratedDataPlaceholder clearFilters={handleResetFilters} />
  ) : (
    <NoDataPlaceholder />
  );

  useEffect(() => {
    if (!query.view) {
      history.replace(
        updateQuery({
          [ANALYTICS_VIEW_QUERY_KEY]: ANALYTICS_EXPENSES_VIEW.MONTH,
        })
      );
    }
  }, [query]);

  return (
    <NoDataContainer selector={noDataSelector} noDataContent={noDataContent} item xs>
      <ScrollMemory elementID={ANALYTICS_EXPENSES_LIST_CONTAINER_ID} />

      <AnalyticsExpensesHeader />
      <AnalyticsExpensesContent />
    </NoDataContainer>
  );
};

export default AnalyticsExpensesRoot;
