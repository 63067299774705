import TableBody from '@mui/material/TableBody';
import { useAppSelector } from 'src/hooks/useAppSelector';
import React from 'react';
import { AnalyticsExpensesMyoceanArticlesGeneralTableRow } from '..';
import selector from './selector';

const AnalyticsExpensesMyoceanArticlesGeneralTableBody = () => {
  const { items, filters, boatId, amountFormatter } = useAppSelector(selector);

  return (
    <TableBody>
      {items?.map((costArticle, index) => (
        <AnalyticsExpensesMyoceanArticlesGeneralTableRow
          key={costArticle.id}
          costArticle={costArticle}
          boatId={boatId}
          dateFrom={filters.dateFrom}
          dateTo={filters.dateTo}
          amountFormatter={amountFormatter}
          isFirstRow={index === 0}
        />
      ))}
    </TableBody>
  );
};

export default AnalyticsExpensesMyoceanArticlesGeneralTableBody;
