import { shouldShowRub } from 'src/app/selectors';
import { getAmountFormatter } from 'src/dictionary/selector';
import { getAnalyticsClientArticlesTotal } from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getAnalyticsClientArticlesTotal, getAmountFormatter, shouldShowRub],
  (analyticsTotal, amountFormatter, showRub) => {
    const formattedTotal = amountFormatter(analyticsTotal);

    return {
      formattedTotal,
      showRub,
    };
  }
);
