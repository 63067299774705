import { setCurrentVehicleConfig, toggleUserCostArticlesMapping } from 'src/app/actions';
import { responseError } from 'src/app/sagas';
import {
  getAnalyticsExpensesList,
  resetAnalyticsExpensesList,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesRoot/actions';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* updateCostArticlesMappingFromAnalytics() {
  try {
    yield put(toggleUserCostArticlesMapping());
    yield take(setCurrentVehicleConfig);

    yield put(resetAnalyticsExpensesList());
    yield put(getAnalyticsExpensesList());
  } catch (e) {
    yield call(responseError, e);
  }
}

export default [takeLatest(actions.updateCostArticlesMappingFromAnalytics, updateCostArticlesMappingFromAnalytics)];
