import Immutable from '@core-ui/immutable';
import reducer, { List } from '@core-ui/reducers_handlers';
import { handleActions } from 'redux-actions';
import { ICostClientResponseSchemaFlat } from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/types';
import * as actions from './actions';

export interface IAnalyticsExpensesClientArticlesState extends List<ICostClientResponseSchemaFlat> {}

const defaultState = Immutable<IAnalyticsExpensesClientArticlesState>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
});

export default handleActions<typeof defaultState, any>(
  {},
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
