import { COLOURS } from '@core-ui/styles';
import { buttonBaseClasses, tableCellClasses, tableRowClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (isRootArticle?: boolean, isFirstRow?: boolean): Record<string, SxProps<Theme>> => ({
  row: isRootArticle
    ? {
        [`&.${tableRowClasses.root}:nth-of-type(n)>.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
          backgroundColor: COLOURS.HUE_COCONUT.HUE_24,
          borderTop: !isFirstRow ? `2px solid ${COLOURS.HUE_COCONUT.HUE_50}` : undefined,
        },
      }
    : {
        left: 0,
      },

  nameCell: isRootArticle
    ? {
        [`&.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
          paddingY: 2,
          height: '38px',
          minHeight: '38px',
          position: 'sticky',
          left: 0,
        },
      }
    : {
        [`&.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
          paddingLeft: 6,
          position: 'sticky',
          left: 0,
        },
      },

  totalCell: isRootArticle
    ? {
        [`&.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
          paddingY: 2,
          height: '38px',
          minHeight: '38px',
          position: 'sticky',
          left: 380,
        },
      }
    : {
        position: 'sticky',
        left: 380,
      },

  monthCell: isRootArticle
    ? {
        [`&.${tableCellClasses.root}:not(.${tableCellClasses.head})`]: {
          paddingY: 2,
          height: '38px',
          minHeight: '38px',
        },
      }
    : {},

  openInLedgerButton: {
    [`&.${buttonBaseClasses.root}`]: {
      padding: 0,
    },
  },
});
