import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'src/hooks/useAppSelector';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import selector from './selector';

const AnalyticsExpensesMyoceanArticlesGeneralGraph = () => {
  const { formattedTotal, showRub } = useAppSelector(selector);

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
    >
      <Grid item container width="auto" direction="column" gap={2} paddingTop={20}>
        <Typography variant="h1" align="center">
          {formattedTotal}
        </Typography>

        <Typography variant="body2" color="text.secondary" align="center">
          <FormattedMessage id={showRub ? 'total.expenses.rub' : 'total.expenses.eur'} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AnalyticsExpensesMyoceanArticlesGeneralGraph;
