import { COLOURS } from '@core-ui/styles';
import { TableCell } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AmountFormatter } from 'src/app/types/common';
import { endOfMonth, fromUnixTime, getUnixTime } from 'src/app/utils/dates';
import { PlanFactSchema } from 'src/generated';
import { makeUrlToDocumentsFromPlanFactClientArticles } from 'src/pages/analytics/AnalyticsInner/utils';
import { useHistory } from 'react-router-dom';

interface IProps {
  boatId: Nullable<number>;
  costArticleCode: string;
  costArticlePlanFactDiff: Nullable<PlanFactSchema>;
  amountFormatter: AmountFormatter;
}

const factCellStyles: SxProps<Theme> = {
  cursor: 'pointer',
};

const AnalyticsPlanFactClientArticlesTableMonthCell = (props: IProps) => {
  const { boatId, costArticleCode, costArticlePlanFactDiff, amountFormatter } = props;

  const history = useHistory();

  const startMonthUnix = costArticlePlanFactDiff ? String(costArticlePlanFactDiff.start_month) : undefined;
  const endMonthUnix = costArticlePlanFactDiff
    ? String(getUnixTime(endOfMonth(fromUnixTime(costArticlePlanFactDiff.start_month))))
    : undefined;

  const handleOpenInLedger = () => {
    history.push(makeUrlToDocumentsFromPlanFactClientArticles(boatId, costArticleCode, startMonthUnix, endMonthUnix));
  };

  const diffColour =
    costArticlePlanFactDiff?.diff && costArticlePlanFactDiff.diff < 0
      ? COLOURS.HUE_STRAWBERRY.HUE_120
      : COLOURS.HUE_AVOCADO.HUE_120;

  return (
    <>
      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(costArticlePlanFactDiff?.budget)}
        </Typography>
      </TableCell>

      <TableCell align="right" onClick={handleOpenInLedger} sx={factCellStyles}>
        <Typography fontFamily="mono" fontWeight="semiBold">
          {amountFormatter(costArticlePlanFactDiff?.fact)}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <Typography fontFamily="mono" fontWeight="semiBold" color={diffColour}>
          {amountFormatter(costArticlePlanFactDiff?.diff)}
        </Typography>
      </TableCell>
    </>
  );
};

export default AnalyticsPlanFactClientArticlesTableMonthCell;
