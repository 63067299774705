import { ScrollMemory } from '@core-ui/redux-router';
import { getCostArticleMappingEnabled } from 'src/app/selectors';
import NoDataContainer from 'src/components/NoDataContainer';
import NoDataPlaceholder from 'src/components/NoDataPlaceholder';
import NoFiltratedDataPlaceholder from 'src/components/NoFiltratedDataPlaceholder';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { resetAnalyticsFilters } from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/actions';
import { getAnalyticsFiltersApplied } from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { getClientArticlesPlanFact } from 'src/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles/actions';
import AnalyticsPlanFactContent from 'src/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactContent';
import AnalyticsPlanFactHeader from 'src/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactHeader';
import { noDataSelector } from 'src/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/selector';
import { TurnOnClientCostArticlesPlaceholder } from 'src/pages/analytics/AnalyticsInner/AnalyticsPlanFact/placeholders';
import React from 'react';
import { batch, useDispatch } from 'react-redux';

export const ANALYTICS_PLAN_FACT_CONTAINER_ID = 'analytics-plan-fact-list-container';

const AnalyticsPlanFactRoot = () => {
  const costArticleMappingEnabled = useAppSelector(getCostArticleMappingEnabled);
  const isFiltersApplied = useAppSelector(getAnalyticsFiltersApplied);

  const dispatch = useDispatch();

  const handleResetFilters = () => {
    batch(() => {
      dispatch(resetAnalyticsFilters());
      dispatch(getClientArticlesPlanFact(null));
    });
  };

  const noDataContent = isFiltersApplied ? (
    <NoFiltratedDataPlaceholder clearFilters={handleResetFilters} />
  ) : (
    <NoDataPlaceholder />
  );

  // TODO: временная заглушка, заменим её на тернарный оператор в внутри компоненте когда будем делать MyOceanArticlesPlanFact
  if (!costArticleMappingEnabled) {
    return <TurnOnClientCostArticlesPlaceholder />;
  }

  return (
    <NoDataContainer selector={noDataSelector} noDataContent={noDataContent} item xs>
      <ScrollMemory elementID={ANALYTICS_PLAN_FACT_CONTAINER_ID} />

      <AnalyticsPlanFactHeader />
      <AnalyticsPlanFactContent />
    </NoDataContainer>
  );
};

export default AnalyticsPlanFactRoot;
