import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import {
  AnalyticsExpensesClientArticlesMonthTableBody,
  AnalyticsExpensesClientArticlesMonthTableHeader,
} from './components';

const AnalyticsExpensesClientArticlesMonthTable = () => {
  return (
    <Grid item container>
      <Table stickyHeader>
        <AnalyticsExpensesClientArticlesMonthTableHeader />
        <AnalyticsExpensesClientArticlesMonthTableBody />
      </Table>
    </Grid>
  );
};

export default AnalyticsExpensesClientArticlesMonthTable;
