import Table from '@mui/material/Table';
import React from 'react';
import {
  AnalyticsExpensesMyoceanArticlesGeneralTableBody,
  AnalyticsExpensesMyoceanArticlesGeneralTableHeader,
} from './components';

const AnalyticsExpensesMyoceanArticlesGeneralTable = () => {
  return (
    <Table stickyHeader>
      <AnalyticsExpensesMyoceanArticlesGeneralTableHeader />
      <AnalyticsExpensesMyoceanArticlesGeneralTableBody />
    </Table>
  );
};

export default AnalyticsExpensesMyoceanArticlesGeneralTable;
