import Typography from '@mui/material/Typography';
import { AmountFormatter } from 'src/app/types/common';

interface IProps {
  amount: number;
  amountFormatter: AmountFormatter;
  isRootArticle?: boolean;
}

const AnalyticsExpensesMyoceanArticlesMonthTableCell = (props: IProps) => {
  const { amount, amountFormatter, isRootArticle } = props;

  return (
    <Typography
      fontFamily="mono"
      fontWeight={isRootArticle ? 'semiBold' : undefined}
      fontSize={isRootArticle ? 12 : undefined}
    >
      {amountFormatter(amount)}
    </Typography>
  );
};

export default AnalyticsExpensesMyoceanArticlesMonthTableCell;
