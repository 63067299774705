import { createAction } from 'redux-actions';

export const reducerName = 'analytics.plan_fact';

export const turnOnCostArticlesMappingFromAnalyticsPlanFact = createAction(
  `${reducerName}TURN_ON_COST_ARTICLES_MAPPING_FROM_ANALYTICS_PLAN_FACT`
);

export const getAnalyticsPlanFact = createAction(`${reducerName}.GET_ANALYTICS_PLAN_FACT`);
export const resetAnalyticsPlanFact = createAction(`${reducerName}.RESET_ANALYTICS_PLAN_FACT`);
export const setAnalyticsPlanFactLoading = createAction<boolean>(`${reducerName}.SET_ANALYTICS_PLAN_FACT_LOADING`);
