import { getCostArticleMappingEnabled } from 'src/app/selectors';
import {
  getClientArticlesAnalyticsExpensesList,
  resetClientArticlesAnalyticsExpensesList,
  setClientArticlesAnalyticsExpensesLoading,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesClientArticles/actions';
import {
  getMyoceanArticlesAnalyticsExpensesList,
  resetMyoceanArticlesAnalyticsExpensesList,
  setMyoceanArticlesAnalyticsExpensesLoading,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/actions';
import { Action } from 'redux-actions';
import { put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

function* getAnalyticsExpensesList() {
  const costArticleMappingEnabled: boolean = yield select(getCostArticleMappingEnabled);

  if (costArticleMappingEnabled) {
    yield put(getClientArticlesAnalyticsExpensesList(null));
  } else {
    yield put(getMyoceanArticlesAnalyticsExpensesList(null));
  }
}

function* resetAnalyticsExpensesList() {
  const costArticleMappingEnabled: boolean = yield select(getCostArticleMappingEnabled);

  if (costArticleMappingEnabled) {
    yield put(resetClientArticlesAnalyticsExpensesList());
  } else {
    yield put(resetMyoceanArticlesAnalyticsExpensesList());
  }
}

function* setAnalyticsExpensesListLoading({ payload }: Action<boolean>) {
  const costArticleMappingEnabled: boolean = yield select(getCostArticleMappingEnabled);

  if (costArticleMappingEnabled) {
    yield put(setClientArticlesAnalyticsExpensesLoading({ loading: payload }));
  } else {
    yield put(setMyoceanArticlesAnalyticsExpensesLoading({ loading: payload }));
  }
}

export default [
  takeLatest(actions.getAnalyticsExpensesList, getAnalyticsExpensesList),
  takeLatest(actions.resetAnalyticsExpensesList, resetAnalyticsExpensesList),
  takeLatest(actions.setAnalyticsExpensesListLoading, setAnalyticsExpensesListLoading),
];
