import { Route } from '@core-ui/redux-router';
import { IRouteParams, ROUTES } from 'src/app/consts/routes';
import { ANALYTICS_INNER_TABS, ANALYTICS_INNER_TABS_PATH } from 'src/pages/analytics/AnalyticsInner/consts';
import { ANALYTICS_TABS } from 'src/pages/analytics/consts';
import { IAnalyticsState } from 'src/pages/analytics/reducer';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import analyticsInnerExpensesRoutes from './AnalyticsExpenses/routes';
import AnalyticsInnerRoot from './AnalyticsInnerRoot';
import analyticsInnerPlanFactRoutes from './AnalyticsPlanFact/routes';

const key: keyof IAnalyticsState = 'analyticsInner';

export const ANALYTICS_INNER_ROUTE: Route = {
  key,
  path: `/:boatId([0-9]+)/:route(${ROUTES.ANALYTICS})/:analyticsTab(${ANALYTICS_TABS.ANALYTICS})/:analyticsInnerTab(${ANALYTICS_INNER_TABS_PATH})`,
  exact: true,
  component: AnalyticsInnerRoot,
  routes: [...analyticsInnerExpensesRoutes, ...analyticsInnerPlanFactRoutes],
};

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.ANALYTICS})/:analyticsTab(${ANALYTICS_TABS.ANALYTICS})`,
    component: (props: RouteComponentProps<IRouteParams>) => (
      <Redirect to={`${props.location.pathname}/${ANALYTICS_INNER_TABS.EXPENSES}`} />
    ),
  },
  ANALYTICS_INNER_ROUTE,
];

export default routes;
