import Button from '@core-ui/button';
import { TableCell, TableRow } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AmountFormatter } from 'src/app/types/common';
import { IFormattedCostArticleResponseSchema } from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/types';
import { makeUrlToDocumentsFromExpensesMyoceanArticles } from 'src/pages/analytics/AnalyticsInner/utils';
import { ArrowSquareOut } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';

interface IProps {
  costArticle: IFormattedCostArticleResponseSchema;
  boatId: Nullable<number>;
  dateFrom: Nullable<string>;
  dateTo: Nullable<string>;
  amountFormatter: AmountFormatter;
  isFirstRow: boolean;
}

const AnalyticsExpensesMyoceanArticlesGeneralTableRow = (props: IProps) => {
  const { costArticle, boatId, dateFrom, dateTo, amountFormatter, isFirstRow } = props;

  const history = useHistory();
  const styles = useStyles(costArticle.isRootArticle, isFirstRow);

  const handleOpenInLedger = () => {
    history.push(makeUrlToDocumentsFromExpensesMyoceanArticles(boatId, costArticle, dateFrom, dateTo));
  };

  return (
    <TableRow sx={styles.row}>
      <TableCell sx={styles.nameCell}>
        <Grid item container justifyContent="space-between">
          <Typography
            fontWeight={costArticle.isRootArticle ? 'semiBold' : undefined}
            fontSize={costArticle.isRootArticle ? 12 : undefined}
          >
            {costArticle.name}
          </Typography>

          <Tooltip title={<FormattedMessage id="tooltip.open_relevant_documents_in_ledger" />} placement="top" arrow>
            <Button
              variant="text"
              // TODO: compact, size="small" и minimalSpacing не работают и не позволяют убрать паддинги иконки, нужно фиксить это в корке
              sx={styles.openInLedgerButton}
              onClick={handleOpenInLedger}
              icon={
                <SvgIcon fontSize="small">
                  <ArrowSquareOut weight="bold" />
                </SvgIcon>
              }
            />
          </Tooltip>
        </Grid>
      </TableCell>

      <TableCell align="right" sx={styles.totalCell}>
        <Typography fontFamily="mono" fontWeight="semiBold" fontSize={costArticle.isRootArticle ? 12 : undefined}>
          {amountFormatter(costArticle.total_price)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default AnalyticsExpensesMyoceanArticlesGeneralTableRow;
