import TableBody from '@mui/material/TableBody';
import { useAppSelector } from 'src/hooks/useAppSelector';
import React from 'react';
import { AnalyticsExpensesClientArticlesGeneralTableRow } from '..';
import selector from './selector';

const AnalyticsExpensesClientArticlesGeneralTableBody = () => {
  const { items, filters, boatId, amountFormatter } = useAppSelector(selector);

  return (
    <TableBody>
      {items?.map((costArticle) => (
        <AnalyticsExpensesClientArticlesGeneralTableRow
          key={costArticle.code}
          costArticle={costArticle}
          boatId={boatId}
          dateFrom={filters.dateFrom}
          dateTo={filters.dateTo}
          amountFormatter={amountFormatter}
        />
      ))}
    </TableBody>
  );
};

export default AnalyticsExpensesClientArticlesGeneralTableBody;
