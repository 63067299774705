import { useTableDynamicShadows } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import Grid from '@mui/material/Grid';
import React, { useRef } from 'react';
import AnalyticsPlanFactClientArticles from 'src/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactClientArticles';
import { ANALYTICS_PLAN_FACT_CONTAINER_ID } from 'src/pages/analytics/AnalyticsInner/AnalyticsPlanFact/AnalyticsPlanFactRoot/AnalyticsPlanFactRoot';
import useStyles from './styles';

const AnalyticsPlanFactContent = () => {
  const styles = useStyles();
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const applyRef = useTableDynamicShadows(ref);

  return (
    <Grid
      item
      container
      sx={styles.contentContainer}
      id={ANALYTICS_PLAN_FACT_CONTAINER_ID}
      ref={applyRef}
    >
      <AnalyticsPlanFactClientArticles />
    </Grid>
  );
};

export default AnalyticsPlanFactContent;
