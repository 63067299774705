import { createSelector } from 'reselect';
import State from 'src/app/types/state';
import { formatTime, getTimestampInMs } from 'src/app/utils/dates';
import { getAmountFormatter } from 'src/dictionary/selector';
import { getAnalyticsFiltersSelectedMonths } from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import { IMonthTotal } from 'src/pages/analytics/AnalyticsInner/types';

const getAnalyticsByCostArticle = (state: State) => state.analytics.analyticsInner.expenses.clientArticles;

export default createSelector(
  [getAnalyticsByCostArticle, getAnalyticsFiltersSelectedMonths, getAmountFormatter],
  (analyticsCostArticlesList, selectedMonths, amountFormatter) => {
    const items: IMonthTotal[] = (
      analyticsCostArticlesList.value?.map(
        (costArticle) =>
          costArticle.price_per_month?.map((item) => {
            return {
              monthName: formatTime(getTimestampInMs(item.start_month), { dateFormat: 'MMMM-YY' }),
              total: item.total_price,
              ...item,
            };
          }) ?? []
      ) ?? []
    ).flat();

    return {
      items,
      selectedMonths,
      amountFormatter,
    };
  }
);
