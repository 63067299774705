import Table from '@mui/material/Table';
import React from 'react';
import {
  AnalyticsExpensesClientArticlesGeneralTableBody,
  AnalyticsExpensesClientArticlesGeneralTableHeader,
} from './components';

const AnalyticsExpensesClientArticlesGeneralTable = () => {
  return (
    <Table stickyHeader>
      <AnalyticsExpensesClientArticlesGeneralTableHeader />
      <AnalyticsExpensesClientArticlesGeneralTableBody />
    </Table>
  );
};

export default AnalyticsExpensesClientArticlesGeneralTable;
