/* eslint-disable @typescript-eslint/ban-ts-comment */
import { NumberValue, ScaleBand } from 'd3-scale';
import * as d3 from './d3';

interface ICornerRound {
  x: number;
  y: number;
}

export interface IScaleBand<Domain> extends ScaleBand<Domain> {
  bandwidth(value: number): this;

  bandwidth(): number;

  offset(value: number): this;

  offset(): number;

  cornerRound(value: number | [number, number]): this;

  cornerRound(): ICornerRound;
}

function initRange<Domain extends { toString(): string } = string>(
  this: IScaleBand<Domain>,
  domain: Iterable<Domain>,
  range: Iterable<NumberValue>
): IScaleBand<Domain> {
  switch (arguments.length) {
    case 0:
      break;
    case 1:
      // @ts-ignore
      this.range(domain);
      break;
    default:
      this.range(range).domain(domain);
      break;
  }

  return this;
}

export function scaleBand<Domain extends { toString(): string } = string>(
  range?: Iterable<NumberValue>
): IScaleBand<Domain>;
export function scaleBand<Domain extends { toString(): string } = string>(
  domain: Iterable<Domain>,
  range: Iterable<NumberValue>
): IScaleBand<Domain>;

export function scaleBand<Domain extends { toString(): string } = string>(): IScaleBand<Domain> {
  const scale = d3.scaleOrdinal().unknown(undefined) as unknown as IScaleBand<Domain>;
  const ordinalDomain = scale.domain;
  const ordinalRange = scale.range;

  let rangeStart = 0;
  let rangeEnd = 1;
  let bandwidth = 0;
  let offset = 0;
  let cornerRound = { x: 0, y: 0 };

  let round = false;

  // @ts-ignore
  delete scale.unknown;

  function rescale() {
    const dataLength = ordinalDomain().length;
    const values = d3.range(dataLength).map((idx) => offset + (offset + bandwidth) * idx);

    return ordinalRange(values);
  }

  // @ts-ignore
  scale.domain = function (domain: Iterable<Domain>) {
    return arguments.length ? (ordinalDomain(domain), rescale()) : ordinalDomain();
  };

  // @ts-ignore
  scale.range = function (range: number[]): IScaleBand | number[] {
    if (arguments.length) {
      const [newStart, newEnd] = range;
      rangeStart = +newStart;
      rangeEnd = +newEnd;

      return rescale();
    }

    return [rangeStart, rangeEnd];
  };

  // @ts-ignore
  scale.round = function (roundFlag?: boolean) {
    if (arguments.length) {
      round = !!roundFlag;

      return rescale();
    }

    return round;
  };

  // @ts-ignore
  scale.cornerRound = function (value: number | [number, number]): IScaleBand | ICornerRound {
    if (arguments.length) {
      cornerRound = {
        x: Array.isArray(value) ? value[0] : value,
        y: Array.isArray(value) ? value[1] : value,
      };

      return rescale();
    }

    return cornerRound;
  };

  // @ts-ignore
  scale.bandwidth = function (width: number): IScaleBand | number {
    if (arguments.length) {
      bandwidth = width;

      return rescale();
    }

    return bandwidth;
  };

  // @ts-ignore
  scale.offset = function (width: number): IScaleBand | number {
    if (arguments.length) {
      offset = width;

      return rescale();
    }

    return offset;
  };

  scale.copy = function () {
    return scaleBand(ordinalDomain(), [rangeStart, rangeEnd]).bandwidth(bandwidth).offset(offset);
  };

  // @ts-ignore
  // eslint-disable-next-line prefer-rest-params
  return initRange.apply(rescale(), arguments);
}
