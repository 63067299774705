import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { ROUTES } from 'src/app/consts/routes';
import AnalyticsExpensesRoot from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesRoot';
import {
  getAnalyticsExpensesList,
  resetAnalyticsExpensesList,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesRoot/actions';
import {
  resetAnalyticsFilters,
  setAnalyticsFiltersFromQuery,
  updateAnalyticsFiltersQuery,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/actions';
import { ANALYTICS_INNER_TABS } from 'src/pages/analytics/AnalyticsInner/consts';
import { IAnalyticsInnerState } from 'src/pages/analytics/AnalyticsInner/reducer';
import { ANALYTICS_TABS } from 'src/pages/analytics/consts';

const key: keyof IAnalyticsInnerState = 'expenses';

export const routes: Route[] = [
  {
    key,
    path: `/:boatId([0-9]+)/:route(${ROUTES.ANALYTICS})/:analyticsTab(${ANALYTICS_TABS.ANALYTICS})/:analyticsInnerTab(${ANALYTICS_INNER_TABS.EXPENSES})`,
    exact: true,
    component: AnalyticsExpensesRoot,
    effects: [
      { events: ['on_enter'], trigger: resetAnalyticsExpensesList },
      { events: ['on_enter'], trigger: resetAnalyticsFilters },
      { events: ['on_enter'], trigger: setAnalyticsFiltersFromQuery },
      { events: ['on_enter'], trigger: updateAnalyticsFiltersQuery },
      { events: ['on_enter'], trigger: getAnalyticsExpensesList },
    ],
  },
];

export const PATHS = extractFlattenPaths(routes);

export default routes;
