import { HEIGHT_SIZE } from '@core-ui/styles';
import { containerClasses } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    [`&.${containerClasses.root}`]: {
      height: `calc(100vh - (${HEIGHT_SIZE.headerHeight}px))`,
      display: 'flex',
      flexDirection: 'row',
      gap: '12px',
      padding: 0,
      overflow: 'hidden',
    },
  },
});
