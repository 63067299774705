import { call, put, select, takeLatest } from 'redux-saga/effects';
import { POST } from 'src/api/oceanApi';
import { responseError } from 'src/app/sagas';
import { vehicleIdSelector } from 'src/app/selectors';
import { CostArticleResponseSchema } from 'src/generated';
import { flattenMyOceanCostArticles } from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/components/utils';
import { IFormattedCostArticleResponseSchema } from 'src/pages/analytics/AnalyticsInner/AnalyticsExpenses/AnalyticsExpensesMyoceanArticles/types';
import { IAnalyticsFiltersState } from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/reducer';
import {
  getAnalyticsFilters,
  getAnalyticsFiltersSelectedMonths,
} from 'src/pages/analytics/AnalyticsInner/AnalyticsFilters/selector';
import * as actions from './actions';

function* getMyoceanArticlesAnalyticsExpensesList() {
  try {
    const filters: IAnalyticsFiltersState = yield select(getAnalyticsFilters);
    const boatId: number = yield select(vehicleIdSelector);

    const response: CostArticleResponseSchema[] = yield call(POST, '/cost_articles/get_analytics', {
      boat_id: boatId,
      cost_center_ids: Array.isArray(filters.costCenters)
        ? filters.costCenters
        : filters.costCenters
        ? [filters.costCenters]
        : undefined,
      variability: filters.variability ?? undefined,
      date_from: filters.dateFrom ? Number(filters.dateFrom) : undefined,
      date_to: filters.dateTo ? Number(filters.dateTo) : undefined,
      per_month: true,
    });

    const selectedMonths: string[] = yield select(getAnalyticsFiltersSelectedMonths);
    const flatCostArticles = flattenMyOceanCostArticles(response);

    const formattedValue: IFormattedCostArticleResponseSchema[] = flatCostArticles.map((costArticle) => ({
      ...costArticle,

      formattedPricesPerMonth: selectedMonths.map((month, index) => {
        if (!costArticle.price_per_month) {
          return {
            monthName: month,
            total: 0,
          };
        }

        return {
          monthName: month,
          total: costArticle.price_per_month[index]?.total_price ?? 0,
        };
      }),
    }));

    yield put(
      actions.setMyoceanArticlesAnalyticsExpensesList({
        value: formattedValue,
        hasData: Boolean(response.length),
        isAllDataReceived: true,
      })
    );
  } catch (e) {
    yield call(responseError, e);
    yield put(
      actions.setMyoceanArticlesAnalyticsExpensesList({
        error: e as Error,
        hasData: false,
        isAllDataReceived: false,
      })
    );
  }
}

export default [takeLatest(actions.getMyoceanArticlesAnalyticsExpensesList, getMyoceanArticlesAnalyticsExpensesList)];
